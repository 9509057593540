import { EmailIcon, FacebookIcon, InstagramIcon, PhoneIcon } from "./SocialIcons";

export default function Footer() {
  return (
    <footer className="p-4 bg-gray-900 text-white">
      <div className="flex flex-col">
        <img alt="Footer Logo" className="bg-white px-8 py-2 mb-8 mx-auto" src="/logo-small.webp" width={200} height={100} />

        <div className="color-white grid grid-cols-4 gap-8 mb-8 mx-auto">
          <a href="https://www.instagram.com/_fire_rescue_safety_/">
            <InstagramIcon className={"w-8 h-8"} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61551276415947">
            <FacebookIcon className={"h-8 w-8"} />
          </a>
          <a href="tel:+421949178850">
            <PhoneIcon className={"h-8 w-8"} />
          </a>
          <a href="mailto:firerescuesafety@firerescuesafety.sk">
            <EmailIcon className={"h-8 w-8"} />
          </a>
        </div>
      </div>

      <div className="mb-8 max-w-5xl mx-auto">
        <h2 className="text-center text-3xl mb-4">Naši partneri:</h2>
        <div
          className="grid w-full justify-items-center justify-center gap-4 mx-auto"
          style={{
            gridTemplateColumns: "repeat(auto-fill, 240px)",
          }}
        >
          <div className="flex items-center gap-3">
            <img loading="lazy" src="/partners/partner-1.webp" alt="DHZ Trenčianska Teplá" className="h-12" />
            <h3 className="text-lg">DHZ Trenčianska Teplá</h3>
          </div>
          <div className="flex items-center gap-3 w-full">
            <img loading="lazy" src="/partners/partner-2.webp" alt="DHZ Petržalka" className="h-12" />
            <h3 className="text-xl">DHZ Petržalka</h3>
          </div>
          <div className="flex items-center gap-3 w-full">
            <img loading="lazy" src="/partners/partner-3.webp" alt="DHZ Vlčkovce" className="h-12" />
            <h3 className="text-xl">DHZ Vlčkovce</h3>
          </div>
          <div className="flex items-center gap-3 w-full">
            <img loading="lazy" src="/partners/partner-4.webp" alt="Florian s.r.o." className="h-12" />
          </div>
          <div className="flex items-center gap-3 w-full">
            <img loading="lazy" src="/partners/partner-5.webp" alt="MHZ Martin - Priekopa" className="h-12" />
            <h3 className="text-xl">MHZ Martin - Priekopa</h3>
          </div>
          <div className="flex items-center gap-3 w-full">
            <img loading="lazy" src="/partners/partner-6.webp" alt="DHZ Doľany " className="h-12" />
            <h3 className="text-xl">DHZ Doľany</h3>
          </div>
          <div className="flex items-center gap-3 w-full">
            <img loading="lazy" src="/partners/partner-7.webp" alt="DHZ Rovinka" className="h-12" />
            <h3 className="text-xl">DHZ Rovinka</h3>
          </div>
          <div className="flex items-center gap-3 w-full">
            <img loading="lazy" src="/partners/partner-8.webp" alt="DHZ Ivanka pri Dunaji" className="h-12" />
            <h3 className="text-xl">DHZ Ivanka pri Dunaji</h3>
          </div>
          <div className="flex items-center gap-3 w-full">
            <img loading="lazy" src="/partners/partner-9.webp" alt="DHZ Brodno" className="h-12" />
            <h3 className="text-xl">DHZ Brodno</h3>
          </div>
          <div className="flex items-center gap-3 w-full">
            <img loading="lazy" src="/partners/partner-10.webp" alt="MEDEVAC - Záchranná služba" className="h-12" />
            <h3 className="text-xl">MEDEVAC - Záchranná služba</h3>
          </div>
          <div className="flex items-center gap-3 w-full">
            <img loading="lazy" src="/partners/partner-11.webp" alt="DHZ Trnava - Farárske Pole" className="h-12" />
            <h3 className="text-xl">DHZ Trnava - Farárske Pole</h3>
          </div>
          <div className="flex items-center gap-3 w-full">
            <img loading="lazy" src="/partners/partner-12.webp" alt="M&J Technik, s. r. o." className="h-12 bg-white p-1" />
          </div>
          <div className="flex items-center gap-3 w-full">
            <img loading="lazy" src="/partners/partner-13.webp" alt="DHZ Svätý Jur" className="h-12" />
            <h3 className="text-xl">DHZ Svätý Jur</h3>
          </div>
          <div className="flex items-center gap-3 w-full">
            <img loading="lazy" src="/partners/partner-14.webp" alt="Švec Dizajn" className="h-12" />
            <h3 className="text-xl">Švec Dizajn</h3>
          </div>  
        </div>
      </div>

      <div className="text-center">©{new Date().getFullYear()} Fire & Rescue Safety</div>
    </footer>
  );
}
