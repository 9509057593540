import ReactDOM from "react-dom/client";
import "./index.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import TPO from "./Pages/Services/TPO";
import PAH from "./Pages/Services/PAH";
import ZAS from "./Pages/Services/ZAS";
import PEF from "./Pages/Services/PEF";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <main className="min-h-screen grid grid-rows-[auto_1fr_auto]">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/sluzby/technik-poziarnej-ochrany" element={<TPO />} />
        <Route path="/sluzby/poziarne-asistencne-hliadky" element={<PAH />} />
        <Route path="/sluzby/zdravotne-asistencne-sluzby" element={<ZAS />} />
        <Route path="/sluzby/poradenstvo-event-fire-secuirty" element={<PEF />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </main>
  </BrowserRouter>
);
