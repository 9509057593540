import Contact from "../../Components/Contact";

export default function ZAS() {
  return (
    <div>
      <section className="bg-gray-900 text-white p-8">
        <h1 className="text-center text-4xl max-sm:text-2xl mb-5">Zdravotné asistenčné služby:</h1>
        <p className="text-center max-w-2xl mx-auto">Fire & Rescue Safety zabezpečuje zdravotné asistenčné služby prístupom a technikou.</p>
      </section>

      <section className="p-8 grid grid-cols-1 gap-8 lg:grid-cols-2 max-w-5xl mx-auto">
        <p>Záchranárske jednotky sa zdržujú na stanovisku, aby v prípade potreby boli vždy k dispozícii. Zdravotné jednotky disponujú technikou – a teda sanitnými vozidlami, ale i prenosnými lekárničkami, AED prístrojom a mnohým iným potrebným vybavením. V prípade potreby je zabezpečený transport do najbližšej nemocnice sanitným vozidlom v sprievode lekára alebo zdravotníka. </p>
        <img alt="Záchranné autá Rescue & Safety" src="/images/zdravotna-asistencna-sluzba.webp" className="object-cover object-center max-h-60" width={"100%"} height={"200"}></img>
      </section>
      <section className="p-8 max-w-5xl mx-auto">
        <h2 className="mooli-regular mx-auto text-center text-3xl mb-4">Priestorové zabezpečenie zdravotnej asistenčnej služby:</h2>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 justify-items-center lg:max-w-5xl mx-auto">
          <div className="bg-red-700 text-white px-8 py-4 w-full">
            <h2 className="font-bold text-3xl flex mb-2">Stanovište zdravotnej asistenčnej služby</h2>
            <p> Pod priestorovým vybavením sa na účely zdravotníckeho zabezpečenia hromadných podujatí rozumie priestor určený alebo vyhradený pre zabezpečujúci personál. Tento priestor slúži aj ako stanovisko asistenčnej zdravotnej služby – teda miesto, kde nájde účastník hromadného podujatia v prípade vzniku náhlej nevoľnosti alebo zranenia potrebnú pomoc, slúži na vyšetrenie, ošetrenie a zhromažďovanie zranených a zabezpečovanie ich intimity,</p>
          </div>
          <div className="bg-red-700 text-white px-8 py-4 w-full">
            <h2 className="font-bold text-3xl flex mb-2">Typy priestorového vybavenia</h2>
            <p> Môže byť tvorené samostatnou označenou miestnosťou, stanom, vozidlom. Priestorové vybavenie poskytuje aj zázemie zabezpečujúcemu personálu – teda ochranu pred poveternostnými podmienkami, hlukom, v prípade dlho trvajúcich podujatí aj na oddych, s možnosťou stravovania,</p>
          </div>
          <div className="bg-red-700 text-white px-8 py-4 w-full lg:col-span-2">
            <h2 className="font-bold text-3xl flex mb-2">Počet stanovísk prvej pomoci</h2>
            <p> Podľa veľkosti plochy hromadného podujatia sa odporúča zriadenie viacerých stanovísk prvej pomoci.</p>
          </div>
        </div>
      </section>

      <Contact />
    </div>
  );
}
