import Contact from "../../Components/Contact";


export default function PAH() {
  return (
    <div>
      <section className="bg-gray-900 text-white p-8">
        <h1 className="text-center text-4xl max-sm:text-2xl mb-5">Protipožiarne asistenčné hliadky:</h1>
        <p className="text-center max-w-2xl mx-auto">Fire & Rescue Safety zabezpečuje protipožiarne asistenčné hliadky s profesionálnym prístupom a technikou.</p>
      </section>

      <section className="p-8 grid grid-cols-1 gap-8 lg:grid-cols-[1fr_auto] max-w-5xl mx-auto">
        <div className="w-full">
          <p p className="mb-6">
            Hasičské jednotky pravidelne prechádzajú okolie počas celého trvania podujatia a kontrolujú možné riziká, nedostatky a poprípade navrhujú možné opatrenia. V prípade, že na akcii nie sú prítomní technici požiarnej ochrany, vybraní členovia hasičskej jednotky kontrolujú potrebné vybavenie, ako je hasiaci prístroj, poprípade ďalšie navrhnuté bezpečnostné prostriedky, ako je protipožiarna deka.
          </p>
          <p className="mb-6">
            Členovia protipožiarnej asistenčnej hliadky zriadenej pri podujatiach, na ktorých sa zúčastňuje väčší počet osôb, sú označení na viditeľnej časti odevu nápisom <span className="font-bold text-red-600">HASIČI.</span>
          </p>
          <p> Členovia protipožiarnej asistenčnej hliadky sa nepoverujú inými úlohami ani výkonom činností, ktoré nesúvisia s plnením ich úloh, ak sa protipožiarna asistenčná hliadka skladá z dvoch alebo viacerých členov, môžu ju okrem vedúceho tvoriť členovia vykonávajúce pracovné činnosti, pre ktoré bola táto hliadka zriadená.</p>
        </div>
        <img src="/images/poziarne-asistencne-hliadky-1.webp" alt="Hasič Fire Safety & Rescue" className="object-cover object-center lg:max-w-sm" width={"100%"} height={"200"}></img>
      </section>

      <section className="p-8 max-w-5xl mx-auto">
        <h2 className="mooli-regular mx-auto text-center text-3xl mb-4">Kedy sa zriaďuje protipožiarna asistenčná hliadka, ak je na podujatí prítomných viac ako:</h2>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-4 justify-items-center lg:max-w-5xl mx-auto">
          <div className="bg-red-700 text-white px-8 py-4 lg:max-w-md w-full">
            <h2 className="font-bold text-3xl flex mb-2">500 osôb</h2>
            <p>sústredených v nekrytých inžinierskych stavbách,</p>
          </div>
          <div className="bg-red-700 text-white px-8 py-4 lg:max-w-md w-full">
            <h2 className="font-bold text-3xl flex mb-2">300 osôb</h2>
            <p>sústredených na prvom nadzemnom podlaží objektu,</p>
          </div>
          <div className="bg-red-700 text-white px-8 py-4 lg:max-w-md w-full">
            <h2 className="font-bold text-3xl flex mb-2">200 osôb</h2>
            <p>sústredných na druhom nadzemnom podlaží alebo v každom ďalšom nadzemnom podlaží objektu,</p>
          </div>
          <div className="bg-red-700 text-white px-8 py-4 lg:max-w-md w-full">
            <h2 className="font-bold text-3xl flex mb-2">100 osôb</h2>
            <p>sústredných v podzemnom podlaží objektu. </p>
          </div>
        </div>
      </section>

      <section className="p-8 max-w-5xl mx-auto">
        <h2 className="mooli-regular mx-auto text-center text-3xl mb-4">Protipožiarna asistenčná hliadka:</h2>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          <img src="/images/poziarne-asistencne-hliadky-2.webp" alt="Tim Fire Rescue & Safety" className="object-cover object-[center_65%] h-full max-h-[550px]" width={"100%"} height={"200"}></img>
          <div className="">
            <div className="flex gap-8 w-full text-lg">
              <p>a)</p>
              <p>Oboznamuje sa s charakterom činnosti alebo s charakterom zabezpečovaného podujatia,</p>
            </div>
            <div className="flex gap-8 w-full text-lg">
              <p>b)</p>
              <p>Oboznamujú sa s objektom a so súvisiacimi opatreniami na zabezpečenie ochrany pred požiarmi, </p>
            </div>
            <div className="flex gap-8 w-full text-lg">
              <p>c)</p>
              <p>Kontrolujú dodržiavanie predpisov upravujúcich zásady protipožiarnej bezpečnosti objektu alebo vykonávanej činnosti, </p>
            </div>
            <div className="flex gap-8 w-full text-lg">
              <p>d)</p>
              <p>Vykonávajú obhliadky daných priestorov, kde sa podujatie alebo činnosť uskutočňujú, a to pred začatím podujatia alebo činnosti, v ich priebehu a po ich skončení po dobu určenú,,</p>
            </div>
            <div className="flex gap-8 w-full text-lg">
              <p>e)</p>
              <p>Preverujú pred začatím činnosti alebo podujatia boli splnené určené opatrenia na zabezpečenie ochrany pred požiarmi, </p>
            </div>
            <div className="flex gap-8 w-full text-lg">
              <p>f)</p>
              <p>Upozorňujú ihneď vedúceho pracoviska alebo organizátora podujatia v prípade bezprostredného nebezpečenstva vzniku požiaru alebo hroziaceho znemožnenia záchrany osôb. </p>
            </div>
          </div>
        </div>
      </section>

      <Contact />
    </div>
  );
}
