import Contact from "../Components/Contact";
import { FireHydrantIcon, FireTruckIcon, HospitalIcon, StadionIcon } from "../Components/ServiceIcons";

function Service({ id, link, icon, title }) {
  return (
    <a id={`service-${id}`} href={link} className={`${id % 2 == 0 ? "bg-red-600 hover:bg-red-500" : "hover:bg-red-500 bg-red-700"} transition-colors duration-700 w-full text-white flex flex-col items-center p-4`}>
      {icon}
      <h3 className="text-center text-xs md:text-base font-bold">{title}</h3>
    </a>
  );
}

export default function Home() {
  return (
    <div>
      <section
        className="min-h-[85vh] bg-red-600 p-4 flex items-end"
        style={{
          background: "linear-gradient(to bottom, #ff000020, #ff0000ff), url(/images/hero.webp)",
          backgroundRepeat: "repeat, no-repeat",
          backgroundSize: "cover, cover",
          backgroundPosition: "center, center",
        }}
      >
        <h1 className="mooli-regular lg:text-5xl mb-10 text-3xl text-white font-bold ml-4 lg:ml-12">Vaša bezpečnosť, naša priorita!</h1>
      </section>
      <section className="bg-gray-900 text-white p-8">
        <div className="grid lg:grid-cols-2 gap-8 max-w-5xl mx-auto">
          <div>
            <h2 className="text-2xl mb-2 font-bold">Víta Vás Fire & Rescue Safety</h2>
            <p>
              Fire & Rescue Safety je partia ľudí, ktorí spojili svoje sily, vedomosti, poznatky i skúsenosti, na základe dlhoročných skúseností v rôznych odvetviach života – kultúrneho života, života hasičského a záchranárskeho – vycítili potrebu vytvorenia silnej a stabilnej spoločnosti, ktorá dokáže zabezpečiť protipožiarnu asistenčnú hliadku, zdravotné asistenčné služby, ale i služby technika požiarnej ochrany a poradenstvo s event fire security, na rôznych eventoch, koncertoch, festivaloch
              a kultúrno – spoločenských podujatiach, kde sa zhromažďuje väčší počet osôb. Vzhľadom na momentálnu situáciu vo svete, naše služby neustále zdokonalujeme, členovia Fire & Rescue Safety sa pravidelne preškolujú, vzdelávajú a zdokonalujú svoje znalosti
            </p>
          </div>
          <div>
            <img className="w-full h-full object-cover object-center" alt="Fire Rescue & Safety Tím" src="/images/team.webp" />
          </div>
        </div>
      </section>

      <section className="relative">
        <div className="bg-gray-900 h-44 w-full p-4">
          <h2 className="text-center text-white text-2xl font-bold mooli-regular">Služby, ktoré poskytuje Fire & Rescue Safety:</h2>
        </div>
        <div className="h-20 md:h-40"></div>
        <div className="absolute w-full flex justify-center top-24">
          <div className="grid grid-cols-4 relative md:w-3/4">
            <Service id={1} link={"/sluzby/technik-poziarnej-ochrany"} icon={<FireHydrantIcon />} title={"Technik požiarnej ochrany"} />
            <Service id={2} link={"/sluzby/poziarne-asistencne-hliadky"} icon={<FireTruckIcon />} title={"Protipožiarne asistenčné hliadky"} />
            <Service id={3} link={"/sluzby/zdravotne-asistencne-sluzby"} icon={<HospitalIcon />} title={"Zdravotné asistenčné služby"} />
            <Service id={4} link={"/sluzby/poradenstvo-event-fire-secuirty"} icon={<StadionIcon />} title={"Poradenstvo s event fire security"} />
          </div>
        </div>
      </section>

      <Contact />
    </div>
  );
}
