import { useEffect } from "react";

var listItemClass = "transition-colors border-b-2 border-transparent hover:border-black";
export default function Header() {
  function resizeEvent() {
    if (window.innerWidth < 1024) {
      document.querySelector("nav ul").classList.add("hidden");
    } else {
      document.querySelector("nav ul").classList.remove("hidden");
    }
  }
  useEffect(() => {
    resizeEvent();
    window.addEventListener("resize", resizeEvent);
    return () => {
      window.removeEventListener("resize", resizeEvent);
    };
  }, []);

  return (
    <header className="p-4 bg-white text-black flex items-center">
      <a href="/">
        <img src="/logo-large.webp" width={225} height={50} />
      </a>
      <nav className="ml-auto">
        <ul className="bg-white w-full left-0 top-20 absolute z-50 min-[1200px]:top-0 min-[1200px]:bg-transparent min-[1200px]:relative p-4 min-[1200px]:p-0 flex flex-col text-center min-[1200px]:flex-row gap-4">
          <li className={listItemClass}>
            <a href="/sluzby/technik-poziarnej-ochrany">Technik požiarnej ochrany</a>
          </li>
          <li className={listItemClass}>
            <a href="/sluzby/poziarne-asistencne-hliadky">Protipožiarne asistenčné hliadky</a>
          </li>
          <li className={listItemClass}>
            <a href="/sluzby/zdravotne-asistencne-sluzby">Zdravotné asistenčné služby</a>
          </li>
          <li className={listItemClass}>
            <a href="/sluzby/poradenstvo-event-fire-secuirty">Poradenstvo s event fire security</a>
          </li>
        </ul>
        <div
          className="block min-[1200px]:hidden"
          onClick={() => {
            document.querySelector("nav ul").classList.toggle("hidden");
          }}
        >
          <svg className="cursor-pointer block h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Mobile menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </div>
      </nav>
    </header>
  );
}
