import { EmailIcon, FacebookIcon, InstagramIcon, PhoneIcon } from "../Components/SocialIcons";

var contactClassName = "p-4 bg-yellow-400 hover:bg-yellow-200 transition-colors duration-700 text-black flex max-md:gap-x-4 md:flex-col max-sm:justify-start justify-center items-center max-w-[300px] max-[400px]:max-w-[225px]";

export default function Contact() {
  return (
    <section className="p-8 mb-10 max-w-5xl mx-auto flex flex-col">
      <h1 className="mooli-regular text-3xl uppercase text-center">Kontaktujte nás</h1>

      <div className="min-[500px]:flex flex-wrap gap-4 mt-8 mx-auto justify-center">
        <a className={contactClassName} href="https://www.instagram.com/_fire_rescue_safety_/">
          <InstagramIcon className={"h-8 w-8 md:h-16 md:w-16 md:mb-3 shrink-0"} />
          <p className="truncate">_fire_rescue_safety_</p>
        </a>
        <a className={contactClassName} href="https://www.facebook.com/profile.php?id=61551276415947">
          <FacebookIcon className={"h-8 w-8 md:h-16 md:w-16 md:mb-3 shrink-0"} />
          <p className="truncate">Fire & Rescue Safety</p>
        </a>
        <a className={contactClassName} href="tel:+421911150188">
          <PhoneIcon className={"h-8 w-8 md:h-16 md:w-16 md:mb-3 shrink-0"} />
          <div className="flex flex-col">
            <p>+421 911 150 188</p>
          </div>
        </a>
        <a className={contactClassName} href="tel:+421949178850">
          <PhoneIcon className={"h-8 w-8 md:h-16 md:w-16 md:mb-3 shrink-0"} />
          <div className="flex flex-col">
          <p >+421 949 178 850</p>
          </div>
        </a>
        <a className={contactClassName} href="mailto:firerescuesafety@firerescuesafety.sk">
          <EmailIcon className={"h-8 w-8 md:h-16 md:w-16 md:mb-3 shrink-0"} />
          <div className="flex flex-col truncate">
            <p className="truncate" >firerescuesafety@firerescuesafety.sk</p>
          </div>
        </a>
        <a className={contactClassName} href="mailto:marek@firerescuesafety.sk">
          <EmailIcon className={"h-8 w-8 md:h-16 md:w-16 md:mb-3 shrink-0"} />
          <div className="flex flex-col truncate">
            <p className="truncate" >marek@firerescuesafety.sk</p>
          </div>
        </a>
      </div>
    </section>
  );
}
