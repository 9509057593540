import Contact from "../../Components/Contact";

// TU BY SOM PRIDAL VIAC TEXTU

export default function PEF() {
  return (
    <div>
      <section className="bg-gray-900 text-white p-8">
        <h1 className="text-center text-4xl max-sm:text-2xl mb-5">Poradenstvo s event fire security:</h1>
        <p className="text-center max-w-2xl mx-auto">Fire & Rescue Safety zabezpečuje komplexné poradenstvo a realizáciu bezpečnostných opatrení pre ochranu pred požiarmi na hromadných podujatiach, vrátane prípravy dokumentácie OPP a odbornej prípravy protipožiarnej asistenčnej hliadky.</p>
      </section>

      <section className="p-8 max-w-5xl mx-auto">
        <h2 className="mooli-regular mx-auto text-center text-3xl mb-4">Naše služby:</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:grid-cols-3 justify-items-center ">
          <div className="bg-red-700 text-white px-8 py-4 w-full">
            <h2 className="font-bold text-xl flex mb-2">Vypracovanie písomného návrhu opatrení:</h2>
            <p>Vypracovanie podrobného plánu na zabezpečenie ochrany pred požiarmi na podujatiach s väčším počtom osôb.</p>
          </div>
          <div className="bg-red-700 text-white px-8 py-4 w-full">
            <h2 className="font-bold text-xl flex mb-2">Podanie žiadosti:</h2>
            <p>Príprava a podanie potrebných žiadostí na Okresné riaditeľstvo Hasičského a záchranného zboru (OR HaZZ).</p>
          </div>
          <div className="bg-red-700 text-white px-8 py-4 w-full">
            <h2 className="font-bold text-xl flex mb-2">Konzultácia:</h2>
            <p>Konzultácia písomného návrhu opatrení s OR HaZZ.</p>
          </div>
          <div className="bg-red-700 text-white px-8 py-4 w-full">
            <h2 className="font-bold text-xl flex mb-2">Vypracovanie požiarnych evakuačných plánov:</h2>
            <p>Tvorba komplexných požiarnych evakuačných plánov pre podujatia.</p>
          </div>
          <div className="bg-red-700 text-white px-8 py-4 w-full">
            <h2 className="font-bold text-xl flex mb-2">Odborná príprava protipožiarnej asistenčnej hliadky:</h2>
            <p>Realizácia špecializovanej prípravy pre protipožiarne hliadky.</p>
          </div>
          <div className="bg-red-700 text-white px-8 py-4 w-full">
            <h2 className="font-bold text-xl flex mb-2">Zriadenie a vybavenie ohlasovne požiarov:</h2>
            <p>Vybavenie a zabezpečenie činnosti ohlasovne požiarov.</p>
          </div>
          <div className="bg-red-700 text-white px-8 py-4 w-full">
            <h2 className="font-bold text-xl flex mb-2">Príprava dokumentácie:</h2>
            <p>Príprava dokumentácie ochrany pred požiarmy pre dané podujatie.</p>
          </div>
        </div>
      </section>

      <Contact />
    </div>
  );
}
